import React, { useEffect, useState, Suspense } from "react";
import { useSelector, useDispatch } from "react-redux";
import { daily_food_counts } from "../../actions/crud";
import LoadingPage from "../../components/LoadingPage";

import BranchSelection from "../../components/BranchSelection";
import ReactHTMLTableToExcel from "react-html-table-to-excel";

//* Months Addition
import { getMonthOffset } from "../../utils/monthOffsetUtils";
import MonthSelection from "../../components/MonthSelection";

const DailyCountTable = React.lazy(() =>
  import("./components/DailyCountTable")
);

function DailyCount() {
  const [currentBranch, setCurrentBranch] = useState("本社");
  const dispatch = useDispatch();

  const branches = ["本社", "常滑", "知立", "島根", "名古屋", "東京", "博多"];

  //* Months Addition
  const [monthValue, setMonthValue] = useState("this_month");
  const [monthOffset, setMonthOffset] = useState(getMonthOffset("this_month"));
  const months = ["previous_month", "this_month", "next_month"];

  //* Months Addition
  const handleSelection = (e) => {
    const selectedMonth = e.target.value;
    setMonthValue(selectedMonth);
    setMonthOffset(getMonthOffset(selectedMonth));
  };

  useEffect(() => {
    dispatch(daily_food_counts({ branch: currentBranch, monthOffset }));
  }, [dispatch, currentBranch, monthOffset]);

  const data = useSelector((state) => state.crud.data.dailyCount?.data1);
  const data2 = useSelector((state) => state.crud.data?.dailyCount?.data2);

  if (!data) {
    return <LoadingPage />;
  }
  const headers = data[0];

  const Honsha_firstHalf = ["大盛", "おかずのみ", "小計"];
  const Honsha_secondHalf = [
    "こころ大盛",
    "こころおかず",
    "なでしこ",
    "麺＆ご飯",
    "麺",
    "小計",
  ];

  const Tokoname_firstHalf = ["大盛", "おかずのみ", "小計"];
  const Tokoname_secondHalf = [
    "常滑",
    "常滑大盛",
    "すこやかおかず",
    "すこやか",
    "すこやか大盛",
    "こころ〇",
    "麺",
    "小計",
  ];

  const Shimane_firstHalf = [
    "健康小盛(島根)",
    "健康おかず",
    "洋風ランチ",
    "洋風小盛(島根)",
    "洋風おかず",
    "麺",
    "小計",
  ];

  const Shimane_secondHalf = [
    "nicoデラックス(島根)",
    "nicoデラックス大(島根)",
    "小計",
  ];

  const Nagoya_firstHalf = [
    "ミノヤ大盛(名古屋)",
    "ミノヤおかず(名古屋)",
    "麺",
    "小計",
  ];

  const Tokyo_firstHalf = ["小計"];
  const Hakata_firstHalf = ["小計"];

  let firstHalf,
    secondHalf,
    firstRowSpan,
    secondRowSpan,
    firstHeading,
    secondHeading,
    firstCell,
    secondCell,
    FT,
    ST;

  switch (currentBranch) {
    case "本社":
    case "知立":
      firstHalf = Honsha_firstHalf;
      secondHalf = Honsha_secondHalf;
      firstRowSpan = 4;
      secondRowSpan = 7;
      firstHeading = "たつえ";
      firstCell = "普通盛";
      secondHeading = "玉清";
      secondCell = "こころ";
      FT = [2, 5];
      ST = [3, 10];
      break;
    case "常滑":
      firstHalf = Tokoname_firstHalf;
      secondHalf = Tokoname_secondHalf;
      firstRowSpan = 4;
      secondRowSpan = 9;
      firstHeading = "たつえ";
      firstCell = "普通盛";
      secondHeading = "とこなめ";
      secondCell = "常滑おかず";
      FT = [2, 7];
      ST = [3, 12];
      break;
    case "島根":
      firstHalf = Shimane_firstHalf;
      secondHalf = Shimane_secondHalf;
      firstRowSpan = 8;
      secondRowSpan = 4;
      firstHeading = "アゴ弁";
      firstCell = "健康弁当";
      secondHeading = "niconico";
      secondCell = "nicoヘルシー(島根)";
      FT = [6, 2];
      ST = [7, 11];

      break;
    case "名古屋":
      firstHalf = Nagoya_firstHalf;
      secondHalf = null;
      firstRowSpan = 5;
      secondRowSpan = 0;
      firstHeading = "ミノヤ大盛";
      firstCell = "ミノヤ普通(名古屋)";
      secondHeading = "";
      secondCell = "";
      FT = [3];
      ST = [4];

      break;
    case "東京":
      firstHalf = Tokyo_firstHalf;
      secondHalf = null;
      firstRowSpan = 2;
      secondRowSpan = 0;
      firstHeading = "田中新川";
      firstCell = "田中新川(東京)";
      secondHeading = "";
      secondCell = "";
      FT = [0];
      ST = [1];

      break;
    case "博多":
      firstHalf = Hakata_firstHalf;
      secondHalf = null;
      firstRowSpan = 2;
      secondRowSpan = 0;
      firstHeading = "はたなか";
      firstCell = "はたなか日替わり";
      secondHeading = "";
      secondCell = "";
      FT = [0];
      ST = [1];

      break;
    default:
      firstHalf = Shimane_firstHalf;
      secondHalf = null;
      firstRowSpan = 7;
      secondRowSpan = 0;
      firstHeading = "島根";
      firstCell = "健康弁当";
      secondHeading = "";
      secondCell = "";
      FT = [2, 3];
      ST = [3, 8];
      break;
  }

  // Extract counts data from the rest of the array
  const countsData = data.slice(1);

  // Extract the totalSum data for each date
  const totalSums = countsData.map((item) => item.totalSum);

  // Extract counts data excluding the totalSum
  const countsWithoutTotalSum = countsData.map(({ totalSum, ...rest }) => rest);

  // Add the totalSum as the last row for each date
  const updatedCountsData = countsWithoutTotalSum.map((item, index) => ({
    ...item,
    totalSum: totalSums[index],
  }));

  let updatedCountsData2;

  if (currentBranch === "常滑" && data2) {
    // Extract counts data from the rest of the array
    const countsData2 = data2.slice(1);

    // Extract the totalSum data for each date
    const totalSums = countsData2.map((item) => item.totalSum);

    // Extract counts data excluding the totalSum
    const countsWithoutTotalSum2 = countsData2.map(
      ({ totalSum, ...rest }) => rest
    );

    // Add the totalSum as the last row for each date
    updatedCountsData2 = countsWithoutTotalSum2.map((item, index) => ({
      ...item,
      totalSum: totalSums[index],
    }));
  }

  return (
    <div className="w-screen h-screen bg-gradient-to-r from-blue-200 to-cyan-200 flex flex-col">
      <div className="w-full h-full flex flex-col my-4">
        <div className="w-full h-1/5 flex flex-row">
          <div className="w-1/6 h-full flex">
            <div className="ml-10 my-auto">
              <BranchSelection
                currentBranch={currentBranch}
                setCurrentBranch={setCurrentBranch}
                branches={branches}
              />
            </div>
          </div>
          <div className="w-4/6 h-full"></div>
          <div className="w-1/6 h-full flex">
            <div className="mx-auto my-auto flex flex-col">
              <div className="mx-auto">
                <MonthSelection
                  months={months}
                  onChange={handleSelection}
                  monthValue={monthValue}
                />
              </div>
              <div className="bg-gradient-to-r from-cyan-400 to-blue-400 mt-6 hover:bg-gradient-to-bl text-white font-bold py-2 px-4 rounded">
                <ReactHTMLTableToExcel
                  id="test-table-xls-button"
                  table="header-to-xls"
                  sheet="header-to-xls"
                  filename={
                    monthOffset.substring(0, 6) +
                    "Header-月次集計-" +
                    currentBranch
                  }
                  buttonText="見出し-エクスポート"
                />
              </div>
              <div className="bg-gradient-to-r from-cyan-400 to-blue-400 mt-6 hover:bg-gradient-to-bl text-white font-bold py-2 px-4 rounded text-center">
                <ReactHTMLTableToExcel
                  id="test-table-xls-button"
                  table="table-to-xls"
                  sheet="table-to-xls"
                  filename={
                    monthOffset.substring(0, 6) + "-月次集計-" + currentBranch
                  }
                  buttonText="エクスポート"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="my-4 flex flex-col">
          {currentBranch === "常滑" && (
            <div className="my-6  ml-8">
              <kbd className="px-2 py-1.5 text-xl font-semibold text-gray-800 bg-gray-100 border border-gray-200 rounded-lg dark:bg-gray-600 dark:text-gray-100 dark:border-gray-500">
                1階
              </kbd>
            </div>
          )}

          <Suspense fallback={<div>Loading...</div>}>
            <DailyCountTable // Pass the props with their values
              headers={headers}
              firstHalf={firstHalf}
              secondHalf={secondHalf}
              firstRowSpan={firstRowSpan}
              secondRowSpan={secondRowSpan}
              firstHeading={firstHeading}
              secondHeading={secondHeading}
              firstCell={firstCell}
              secondCell={secondCell}
              updatedCountsData={updatedCountsData}
              FT={FT}
              ST={ST}
            />
          </Suspense>
        </div>

        {currentBranch === "常滑" && data2 && (
          <div className="my-4 flex flex-col bg-gradient-to-r from-blue-200 to-cyan-200">
            <div className="my-6  ml-8">
              <kbd className="px-2 py-1.5 text-xl font-semibold text-gray-800 bg-gray-100 border border-gray-200 rounded-lg dark:bg-gray-600 dark:text-gray-100 dark:border-gray-500">
                2階
              </kbd>
            </div>
            <Suspense fallback={<div>Loading...</div>}>
              <DailyCountTable // Pass the props with their values
                headers={headers}
                firstHalf={firstHalf}
                secondHalf={secondHalf}
                firstRowSpan={firstRowSpan}
                secondRowSpan={secondRowSpan}
                firstHeading={firstHeading}
                secondHeading={secondHeading}
                firstCell={firstCell}
                secondCell={secondCell}
                updatedCountsData={updatedCountsData2}
                FT={FT}
                ST={ST}
              />
            </Suspense>
          </div>
        )}
      </div>
    </div>
  );
}

export default DailyCount;
