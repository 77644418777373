import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export default function AuthLayout({
  children,
  authentication = false,
  isAdmin = false,
  isEmployee = false,
}) {
  const navigate = useNavigate();
  const [loader, setLoader] = useState(true);
  const authData = useSelector((state) => state.auth.authData);
  let role;
  if (authData) {
    role = authData?.role;
  }

  useEffect(() => {
    if (!authentication && role === "employee") {
      navigate("/profile");
    } else if (!authentication && role === "admin") {
      navigate("/admin");
    } else if (authentication && isAdmin && role === "employee") {
      navigate("/profile");
    } else if (authentication && isEmployee && role === "admin") {
      navigate("/admin");
    } else if (authentication && !authData) {
      navigate("/");
    }
    setLoader(false);
  }, [role, navigate, authentication, isAdmin, authData, isEmployee, loader]);
  return loader ? <h1>Loading...</h1> : <>{children}</>;
}
