import React from "react";
import { Outlet } from "react-router-dom";
import UserNavbar from "@components/UserNavbar";
import { useSelector } from "react-redux";
import AdminNavbar from "@components/AdminNavbar";

function Layout() {
  const authData = useSelector((state) => state.auth.authData);
  let role;
  if (authData) {
    role = authData?.role; // removed the 'const' keyword
  }

  return (
    <>
      {(!role || role === "employee") && <UserNavbar />}
      {role === "admin" && <AdminNavbar />}
      <Outlet />
    </>
  );
}

export default Layout;
