import React, { useEffect, useState } from "react";
import { tableStyle } from "../../tailwindClasses/Styles";
import { useSelector, useDispatch } from "react-redux";
import { department_wise_data } from "../../actions/crud";
import LoadingPage from "../../components/LoadingPage";
import BranchSelection from "../../components/BranchSelection";
import ReactHTMLTableToExcel from "react-html-table-to-excel";

import FNWC from "../../utils/FNWC";

//* Months Addition
import { getMonthOffset } from "../../utils/monthOffsetUtils";
import MonthSelection from "../../components/MonthSelection";

function DeptWise() {
  const [currentBranch, setCurrentBranch] = useState("本社");
  const dispatch = useDispatch();
  const branches = ["本社", "常滑", "知立", "島根", "名古屋", "東京", "博多"];

  //* Months Addition
  const [monthValue, setMonthValue] = useState("this_month");
  const [monthOffset, setMonthOffset] = useState(getMonthOffset("this_month"));
  const months = ["previous_month", "this_month", "next_month"];

  //* Months Addition
  const handleSelection = (e) => {
    const selectedMonth = e.target.value;
    setMonthValue(selectedMonth);
    setMonthOffset(getMonthOffset(selectedMonth));
  };

  const departmentHeaders = [
    "部署名",
    (() => {
      switch (currentBranch) {
        case "島根":
          return "島根";
        case "常滑":
          return ["たつえ", "とこなめ"];
        case "本社":
        case "知立":
          return ["たつえ", "玉清"];
        default:
          return "玉清";
      }
    })(),
    "合計",
    "控除金額",
    "会社負担額",
  ].flat();

  useEffect(() => {
    dispatch(department_wise_data({ branch: currentBranch, monthOffset }));
  }, [dispatch, currentBranch, monthOffset]);

  const data = useSelector((state) => state.crud.data.deptWiseData);

  if (!data) {
    return <LoadingPage />;
  }

  const calculateColumnTotal = (columnName, skipDeduction = false) => {
    let total = 0;
    for (const [key, departmentData] of Object.entries(data)) {
      if (skipDeduction && (key === "外部" || key === "その他")) {
        // Skip salary_deduction sum when key is "外部" or "その他"
        if (columnName === "salary_deduction") continue;
        // Add company_burden and salary_deduction when key is "外部" or "その他"
        if (columnName === "company_burden") {
          total +=
            departmentData["company_burden"] +
            departmentData["salary_deduction"];
          continue;
        }
      }
      total += departmentData[columnName];
    }
    return total;
  };

  const renderTotalRow = () => {
    const totalValues = {
      tatsue_total: calculateColumnTotal("tatsue_total"),
      ...(currentBranch !== "島根" && {
        tokoname_total: calculateColumnTotal("tokoname_total"),
      }),
      total: calculateColumnTotal("total"),
      salary_deduction: calculateColumnTotal("salary_deduction", true),
      company_burden: calculateColumnTotal("company_burden", true),
    };

    return (
      <tr>
        <td className={tableStyle.td}>Total</td>
        {Object.values(totalValues).map((total, index) => (
          <td key={index} className={tableStyle.td}>
            {FNWC(total)}
          </td>
        ))}
      </tr>
    );
  };

  const { table, thead, th, td, tr } = tableStyle;
  return (
    <div className="w-screen h-screen bg-gradient-to-r from-blue-200 to-cyan-200 flex flex-col">
      <div className="w-full h-1/5 flex flex-row">
        <div className="w-1/6 h-full flex">
          <div className="ml-10 my-auto">
            <BranchSelection
              currentBranch={currentBranch}
              setCurrentBranch={setCurrentBranch}
              branches={branches}
            />
          </div>
        </div>
        <div className="w-4/6 h-full"></div>
        <div className="w-1/6 h-full flex">
          <div className="mx-auto my-auto flex flex-col">
            <div className="mx-auto">
              <MonthSelection
                months={months}
                onChange={handleSelection}
                monthValue={monthValue}
              />
            </div>
            <div className="bg-gradient-to-r from-cyan-400 to-blue-400 mt-6 hover:bg-gradient-to-bl text-white font-bold py-2 px-4 rounded">
              <ReactHTMLTableToExcel
                id="test-table-xls-button"
                table="table-to-xls"
                filename={
                  monthOffset.substring(0, 6) + "-部門別まとめ-" + currentBranch
                }
                buttonText="エクスポート"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="w-full h-4/5 flex">
        <div className="w-2/3 h-full mx-auto">
          <table className={table} id="table-to-xls">
            <thead className={thead}>
              <tr>
                {departmentHeaders.map((header, index) => (
                  <th key={index} className={th}>
                    {header}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {Object.entries(data).map(([department, values]) => (
                <tr key={department} className={tr}>
                  <td className={td}>{department}</td>
                  <td className={td}>{FNWC(values.tatsue_total)}</td>
                  {currentBranch !== "島根" && (
                    <td className={td}>{FNWC(values.tokoname_total)}</td>
                  )}
                  <td className={td}>{FNWC(values.total)}</td>
                  {department === "外部" || department === "その他" ? (
                    <>
                      <td className={td}>{FNWC(0)}</td>
                      <td className={td}>
                        {FNWC(values.company_burden + values.salary_deduction)}
                      </td>
                    </>
                  ) : (
                    <>
                      <td className={td}>{FNWC(values.salary_deduction)}</td>
                      <td className={td}>{FNWC(values.company_burden)}</td>
                    </>
                  )}

                  {/* <td className={td}>{FNWC(values.salary_deduction)}</td>
                  <td className={td}>{FNWC(values.company_burden)}</td> */}
                </tr>
              ))}
            </tbody>
            <tfoot>{renderTotalRow()}</tfoot>
          </table>
        </div>
      </div>
    </div>
  );
}

export default DeptWise;
