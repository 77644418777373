import React from "react";
import { useNavigate } from "react-router-dom";

function NotFound() {
  const navigate = useNavigate();
  return (
    <div className="w-screen h-screen flex flex-col">
      <div className="h-1/3 w-full flex flex-col items-start justify-end">
        <div className="mx-auto text-7xl">404</div>
        <div className="mx-auto text-4xl my-2">ページが見つかりません</div>
      </div>
      <div className="h-2/3 w-full flex">
        <div className="mx-auto mt-10">
          <button
            onClick={() => navigate("/")}
            className="py-2.5 px-5 me-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-full border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
          >
            ホームページへ
          </button>
        </div>
      </div>
    </div>
  );
}

export default NotFound;
