// Dropdown.js
import React from "react";
import { useLanguage } from "../hooks/useLanguage";

const MonthSelection = ({ months, onChange, monthValue = "this_month" }) => {
  const language = useLanguage();

  return (
    <select
      value={monthValue}
      onChange={onChange}
      className="border border-gray-300 mx-auto rounded-full h-10 px-5 bg-white hover:border-gray-400  appearance-none text-center"
    >
      {months.map((month) => (
        <option key={month} value={month}>
          {language.months[month]}
        </option>
      ))}
    </select>
  );
};

export default MonthSelection;
