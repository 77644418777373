import React, { useEffect, useState } from "react";
import { tableStyle } from "@tailwindClasses/Styles";
import { useSelector, useDispatch } from "react-redux";
import {
  fetch_permission_data,
  update_permission_data,
} from "../../actions/crud";
import LoadingPage from "../../components/LoadingPage";
import { useLanguage } from "../../hooks/useLanguage";
import { getMonthOffset } from "../../utils/monthOffsetUtils";
import MonthSelection from "../../components/MonthSelection";

const Permission = () => {
  const dispatch = useDispatch();
  const [monthValue, setMonthValue] = useState("this_month");
  const [monthOffset, setMonthOffset] = useState(getMonthOffset("this_month"));
  const [currentYear, setCurrentYear] = useState("");
  const [currentMonth, setCurrentMonth] = useState("");
  const [tempData, setTempData] = useState([]);

  const language = useLanguage();

  const months = ["this_month", "next_month", "next_next_month"];

  const data = useSelector((state) => state.crud.data.permissionData);

  useEffect(() => {
    dispatch(fetch_permission_data(monthOffset));
  }, [dispatch, monthOffset]);

  useEffect(() => {
    // Extract year and month from monthOffset
    const [year, month] = monthOffset.split("-");
    setCurrentYear(year);
    setCurrentMonth(month);
  }, [monthOffset]);

  useEffect(() => {
    // Synchronize tempData with data from Redux
    setTempData(data);
  }, [data]);

  if (!data) {
    return <LoadingPage />;
  }

  const handleSelection = (e) => {
    const selectedMonth = e.target.value;
    setMonthValue(selectedMonth);
    setMonthOffset(getMonthOffset(selectedMonth));
  };

  const handleCheckbox = (_id, allow) => {
    // Toggle the allow value in tempData
    const updatedData = tempData.map((permission) =>
      permission._id === _id ? { ...permission, allow: !allow } : permission
    );
    setTempData(updatedData);

    // Dispatch the updated permission data to the backend
    dispatch(update_permission_data(_id, !allow, monthOffset));
  };

  return (
    <div className="bg-gradient-to-r from-blue-200 to-cyan-200 w-screen h-screen flex flex-col overflow-y-auto">
      <div className=" w-full h-1/6 flex">
        <div className="mx-auto my-auto text-2xl">
          {language.months.current_month} :: {currentYear} 年 {currentMonth} 月
        </div>
      </div>
      <div className=" w-full h-5/6 flex flex-row">
        <div className="w-1/6  h-full flex">
          {/* <select
            value={monthValue}
            onChange={handleSelection}
            className="border border-gray-300 mx-auto rounded-full h-10 px-5 bg-white hover:border-gray-400  appearance-none text-center"
          >
            {months.map((month) => (
              <option key={month} value={month}>
                {language.months[month]}
              </option>
            ))}
          </select> */}
          <MonthSelection
            months={months}
            onChange={handleSelection}
            monthValue={monthValue}
          />
        </div>
        <div className="w-4/6  h-full">
          <table className={tableStyle.table}>
            <thead className={tableStyle.thead}>
              <tr>
                <th className={tableStyle.th}>日付</th>
                <th className={tableStyle.th}>許可</th>
              </tr>
            </thead>
            <tbody>
              {tempData?.map((permission) => {
                const { _id, date, allow, dayOfWeek } = permission;
                const isHoliday = dayOfWeek === 7 || dayOfWeek === 1; // Saturday or Sunday

                // Check if the date is in the past or today
                const currentDate = new Date();
                const permissionDate = new Date(date);
                const isPastOrToday = permissionDate <= currentDate;

                return (
                  <tr
                    className={`${tableStyle.tr} ${
                      isHoliday ? tableStyle.holiday_tr : ""
                    }`}
                    key={_id}
                  >
                    <td className={tableStyle.td}>{date}</td>
                    {/* <td className={tableStyle.td}> */}
                    <td className={tableStyle.td}>
                      <input
                        type="checkbox"
                        checked={allow}
                        disabled={isPastOrToday}
                        onChange={() => handleCheckbox(_id, allow)}
                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div className="w-1/6  h-full"></div>
      </div>
    </div>
  );
};

export default Permission;
