import * as actionType from "../constants/actionTypes";

const initialState = {
  userInfo: null,
  userFood: null,
  currentDate: null,
  data: {
    monthlySummary: null,
    allFoods: null,
    allEmpInformation: null,
    dailyCount: { data1: null, data2: null },
    checkInData: null,
    singleUserDataByAdmin: null,
    deptWiseData: null,
    permissionData: null,
  },
  users: null,
  isLoading: false,
  errors: "",
};

const crudReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.START_LOADING: {
      return { ...state, isLoading: true };
    }

    case actionType.END_LOADING: {
      return { ...state, isLoading: false };
    }
    case actionType.FETCH_SINGLE_USER_INFO: {
      const data = action.data;
      return { ...state, userInfo: data, isLoading: false, errors: null };
    }
    case actionType.FETCH_SINGLE_USER_FOOD: {
      const data = action.data;
      return { ...state, userFood: data, isLoading: false, errors: null };
    }
    case actionType.MONTHLY_SUMMARY: {
      const data = action.data;
      return {
        ...state,
        data: { ...state.data, monthlySummary: data },
        isLoading: false,
        errors: null,
      };
    }
    case actionType.FETCH_ALL_FOODS: {
      const data = action.data;
      return {
        ...state,
        data: { ...state.data, allFoods: data },
        isLoading: false,
        errors: null,
      };
    }
    case actionType.ALL_EMP_INFORMATION: {
      const data = action.data;
      return {
        ...state,
        data: { ...state.data, allEmpInformation: data },
        isLoading: false,
        errors: null,
      };
    }
    case actionType.DELETE_USER: {
      const updatedAllEmpInformation = state.data.allEmpInformation.filter(
        (employee) => employee.easyproId !== action.data
      );

      return {
        ...state,
        data: {
          ...state.data,
          allEmpInformation: updatedAllEmpInformation,
        },
        isLoading: false,
        errors: null,
      };
    }

    case actionType.INSERT_USER: {
      const data = action.data;
      return {
        ...state,
        data: {
          ...state,
          allEmpInformation: data,
        },
        isLoading: false,
        errors: null,
      };
    }

    case actionType.DAILY_FOOD_COUNTS: {
      const data = action.data.updatedResult;
      const data2 = action.data?.updatedResult2;

      return {
        ...state,
        data: {
          ...state,
          dailyCount: {
            data1: data,
            data2: data2,
          },
        },
        isLoading: false,
        errors: null,
      };
    }

    case actionType.CHECKED_IN_DATA: {
      const data = action.data;
      return {
        ...state,
        data: {
          ...state,
          checkInData: data,
        },
        isLoading: false,
        errors: null,
      };
    }
    case actionType.FETCH_SINGLE_USER_INFO_BY_ADMIN: {
      const data = action.data;
      return {
        ...state,
        data: {
          ...state,
          singleUserDataByAdmin: data,
        },
        isLoading: false,
        errors: null,
      };
    }

    case actionType.DELETE_FOOD_ITEM: {
      const { easyproId, date } = action.data;

      const updatedAllFoods = state.data.allFoods.map((foodData) => {
        if (foodData.easyproId === easyproId) {
          // Map through each item in the data array
          const updatedData = foodData.data.map((item) => {
            // Check if the item's date matches the action's date
            if (item.date === date) {
              // If it matches, return a new object with the name property set to an empty string
              return { ...item, name: "" };
            }
            // If it doesn't match, return the item unchanged
            return item;
          });

          // Return the updated foodData object with the updatedData array
          return {
            ...foodData,
            data: updatedData,
          };
        }
        // If the easyproId doesn't match, return the foodData object unchanged
        return foodData;
      });

      // Return the updated state with the updatedAllFoods array
      return {
        ...state,
        data: {
          ...state.data,
          allFoods: updatedAllFoods,
        },
        isLoading: false,
        errors: null,
      };
    }

    case actionType.LOGOUT: {
      return initialState;
    }
    case actionType.INSERT_FOOD: {
      return {
        ...state,
        isLoading: false,
        errors: null,
      };
    }
    case actionType.DEPARTMENT_WISE_DATA: {
      const data = action.data;
      return {
        ...state,
        data: {
          ...state,
          deptWiseData: data,
        },
        isLoading: false,
        errors: null,
      };
    }
    case actionType.DATE_CHANGE: {
      const data = action.data;
      return {
        ...state,
        currentDate: data,
        isLoading: false,
        errors: null,
      };
    }
    case actionType.FETCH_PERMISSION: {
      const data = action.data;
      return {
        ...state,
        data: {
          ...state,
          permissionData: data,
        },
        isLoading: false,
        errors: null,
      };
    }

    case actionType.GENERATE_FOODS: {
      return {
        ...state,
        isLoading: false,
        errors: null,
      };
    }

    case actionType.UPDATE_CHECKBOX_BY_ADMIN: {
      const { _id, branch } = action.data;

      // Find the branch data within the checkInData object
      const branchData = state.data.checkInData[branch];

      // Toggle the checkedIn value for the item with the matching _id
      const updatedBranchData = branchData.map((item) => {
        if (item._id === _id) {
          return {
            ...item,
            checkedIn: !item.checkedIn, // Toggle the value
          };
        }
        return item;
      });

      // Update the state with the toggled branch data
      return {
        ...state,
        data: {
          ...state.data,
          checkInData: {
            ...state.data.checkInData,
            [branch]: updatedBranchData,
          },
        },
        isLoading: false,
        errors: null,
      };
    }

    default: {
      return state;
    }
  }
};

export default crudReducer;
