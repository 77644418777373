//* api Texts
const USER_API_TEXT = `user-data`;
const USER_INFO_API_TEXT = `user-info`;
const FOOD_API_TEXT = `food-data`;
const PERMISSION_API_TEXT = `permission-api`;

//* Food Routes
export const INSERT_FOOD = `/${FOOD_API_TEXT}/insertfood`;
export const FETCH_SINGLE_USER_FOOD = `/${FOOD_API_TEXT}/fetchsingleuserfoods`;
export const FETCH_ALL_FOODS = `/${FOOD_API_TEXT}/fetchallfoods`;
export const DELETE_FOOD_ITEM = `/${FOOD_API_TEXT}/deletefooditem`;
export const GET_FOOD_DATA = `/${FOOD_API_TEXT}/getfooddata`;
export const DAILY_FOOD_COUNTS = `/${FOOD_API_TEXT}/dailyfoodcounts`;
export const FETCH_SINGLE_USER_INFO_BY_ADMIN = `/${FOOD_API_TEXT}/a`;
export const CHECKED_IN_DATA = `/${FOOD_API_TEXT}/b`;
export const DEPARTMENT_WISE_DATA = `/${FOOD_API_TEXT}/d`;
export const GENERATE_FOOD = `/${FOOD_API_TEXT}/datagenerator`;

//* Permission Routes
export const PERMISSION_DATA = `/${PERMISSION_API_TEXT}/permissions`;
export const UPDATE_PERMISSIONS = `/${PERMISSION_API_TEXT}/update-permissions`;

//* User Routes
export const USER_LOGIN = `/${USER_API_TEXT}/login`;
export const USER_REGISTRATION = `/${USER_API_TEXT}/register`;
export const VERIFY_TOKEN = `/${USER_API_TEXT}/verifytoken`;

//* UserInfo Routes
export const FETCH_ALL_USER_INFO = `/${USER_INFO_API_TEXT}/fetchalluserinfo`;
export const DELETE_USER = `/${USER_INFO_API_TEXT}/deleteuser`;
export const INSERT_USER = `/${USER_INFO_API_TEXT}/insertUser`;
export const UPDATE_USER = `/${USER_INFO_API_TEXT}/update-user`;
export const FETCH_SINGLE_USER_INFO = `/${USER_INFO_API_TEXT}/fetchsingleuserinfo`;
