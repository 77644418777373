// BranchSelection.js

import React from "react";

function BranchSelection({ currentBranch, setCurrentBranch, branches }) {
  return (
    <select
      value={currentBranch}
      onChange={(e) => setCurrentBranch(e.target.value)}
      className="border border-gray-300 rounded-full h-10 px-5 bg-white hover:border-gray-400  appearance-none text-center"
    >
      {branches.map((branch) => (
        <option key={branch} value={branch}>
          {branch}
        </option>
      ))}
    </select>
  );
}

export default BranchSelection;
