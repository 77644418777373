import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import LoadingPage from "../../components/LoadingPage";
import { useLanguage } from "../../hooks/useLanguage";
import { delete_user, fetch_all_emp_information } from "../../actions/crud";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import ReactHTMLTableToExcel from "react-html-table-to-excel";

import { tableStyle, buttonStyle } from "../../tailwindClasses/Styles";
import InsertUserModal from "../../components/InsertUserModal";

import GenerateFood from "./GenerateFood";

function EmployeeInformation() {
  const [workLocationFilter, setWorkLocationFilter] = useState("みんな");
  const dispatch = useDispatch();
  const language = useLanguage();
  const [removeUser, setRemoveUser] = useState(false);
  const [updateUser, setUpdateUser] = useState(false);
  const [showModal, setShowModal] = useState(false); // State to control modal visibility
  const [modalMessage, setModalMessage] = useState("");

  // State variables to manage user details for adding and updating users
  const [userDetails, setUserDetails] = useState({
    easyproId: 0,
    name: "",
    employee_status: "",
    company_group: "",
    team: "",
    work_location: "",
  });

  useEffect(() => {
    dispatch(fetch_all_emp_information());
  }, [dispatch, workLocationFilter]);

  const details = useSelector((state) => state.crud.data.allEmpInformation);

  const delUser = (id) => {
    const easyproId = id;
    dispatch(delete_user(easyproId));
  };

  const branches = [
    "みんな",
    "本社",
    "常滑",
    "知立",
    "島根",
    "名古屋",
    "東京",
    "博多",
  ];

  const filterByWorkLocation = (location) => {
    setWorkLocationFilter(location);
  };

  const getFilteredDetails = () => {
    if (workLocationFilter === "みんな") {
      return details;
    } else {
      return details.filter(
        (detail) => detail.work_location === workLocationFilter
      );
    }
  };

  // Function to handle adding user
  const handleAddUser = () => {
    setUserDetails({
      easyproId: 0,
      name: "",
      employee_status: "",
      company_group: "",
      team: "",
      work_location: "",
    });
    setShowModal(true);
    setModalMessage("Add User");
  };

  // Function to handle updating user
  const handleUpdateUser = (detail) => {
    setUserDetails(detail);
    setShowModal(true);
    setModalMessage("Update User");
  };

  if (!details) {
    return <LoadingPage />;
  }

  return (
    <div className="w-screen h-auto bg-gradient-to-r from-blue-200 to-cyan-200 flex flex-col overflow-y-auto">
      <div className="w-full h-1/5  flex flex-row mt-10 ">
        <div className="w-1/6 h-full  flex flex-col">
          <div className="w-1/2">
            <div className="mt-4 ml-4">
              <select
                className="border border-gray-300 my-auto ml-5 rounded-full text-gray-600 h-10 px-5 bg-white hover:border-gray-400 focus:outline-none appearance-none text-center"
                value={workLocationFilter}
                onChange={(e) => filterByWorkLocation(e.target.value)}
              >
                {branches.map((branch) => (
                  <option key={branch} value={branch}>
                    {branch}
                  </option>
                ))}
              </select>
            </div>
            <div className="ml-6">
              <div className="bg-gradient-to-r from-cyan-400 to-blue-400 mt-6 hover:bg-gradient-to-bl text-white font-bold py-2 px-4 rounded">
                <ReactHTMLTableToExcel
                  id="test-table-xls-button"
                  table="table-to-xls"
                  filename={workLocationFilter + "のデータ"}
                  buttonText="エクスポート"
                />
              </div>
            </div>
          </div>
          <div className="w-1/2"></div>
        </div>
        <div className="w-4/6 h-full  flex">
          <div className="mx-auto my-auto text-5xl font-mono tracking-wider text-amber-950">
            {language.employee_information_page.employee_information}
          </div>
        </div>
        <div className="w-1/6 h-full  flex">
          <div className="ml-auto mr-6 my-auto flex flex-col">
            <button
              className={buttonStyle.special_button}
              onClick={handleAddUser}
            >
              {language.employee_information_page.add_user}
            </button>
            <button
              className={buttonStyle.special_button}
              onClick={() => setRemoveUser((prevState) => !prevState)}
            >
              {language.employee_information_page.remove_user}
            </button>
            <button
              className={buttonStyle.special_button}
              onClick={() => setUpdateUser((prevState) => !prevState)}
            >
              {language.employee_information_page.update_user}
            </button>
          </div>
        </div>
      </div>
      <div className="w-full h-3/5 mt-12   flex">
        <div className="w-5/6  mx-auto">
          <table className={tableStyle.table} id="table-to-xls">
            <thead className={tableStyle.thead}>
              <tr>
                <th className={tableStyle.th}>S. N.</th>
                <th className={tableStyle.th}>EasyPro Id</th>
                <th className={tableStyle.th}>
                  {language.employee_profile.name}
                </th>
                <th className={tableStyle.th}>
                  {language.employee_profile.employment_status}
                </th>
                <th className={tableStyle.th}>
                  {language.employee_profile.group}
                </th>
                <th className={tableStyle.th}>
                  {language.employee_profile.team}
                </th>
                <th className={tableStyle.th}>
                  {language.employee_profile.work_location}
                </th>
                {removeUser && (
                  <th className={tableStyle.th}>{language.remove_user} </th>
                )}
                {updateUser && <th className={tableStyle.th}>Update User</th>}
              </tr>
            </thead>
            {getFilteredDetails().map((detail, index) => {
              const {
                _id,
                easyproId,
                name,
                employee_status,
                company_group,
                team,
                work_location,
              } = detail;
              return (
                <tbody key={_id}>
                  <tr align="center">
                    <td className={tableStyle.td}>{index + 1}</td>
                    <td className={tableStyle.td}>{easyproId}</td>
                    <td className={tableStyle.td}>{name}</td>
                    <td className={tableStyle.td}>{employee_status}</td>
                    <td className={tableStyle.td}>{company_group}</td>
                    <td className={tableStyle.td}>{team}</td>
                    <td className={tableStyle.td}>{work_location}</td>
                    {removeUser && (
                      <td className={tableStyle.td}>
                        <button
                          onClick={() => {
                            delUser(easyproId);
                          }}
                        >
                          <DeleteRoundedIcon sx={{ color: "red" }} />
                        </button>
                      </td>
                    )}
                    {updateUser && (
                      <td className={tableStyle.td}>
                        <button onClick={() => handleUpdateUser(detail)}>
                          <AutorenewIcon sx={{ color: "green" }} />
                        </button>
                      </td>
                    )}
                  </tr>
                </tbody>
              );
            })}
          </table>
        </div>
      </div>
      {/* Render modal */}

      <div className="w-full h-1/5">
        <GenerateFood />
      </div>

      {showModal && (
        <InsertUserModal
          showModal={showModal}
          setShowModal={setShowModal}
          message={modalMessage}
          data={userDetails}
        />
      )}
    </div>
  );
}

export default EmployeeInformation;
