// api.js
import Axios from "axios";
import * as API_ENDPOINTS from "../constants/apiConstants";

const apiUrl = process.env.REACT_APP_API_URL;
const apiKey = process.env.REACT_APP_API_KEY;

const API = Axios.create({ baseURL: apiUrl });
const API2 = Axios.create({ baseURL: apiUrl });

API.interceptors.request.use((req) => {
  if (localStorage.getItem("token")) {
    req.headers.authorization = `Bearer ${JSON.parse(
      localStorage.getItem("token")
    )}`;
  }
  req.headers.apikey = apiKey; // Set the apiKey header
  return req;
});

export const InsertFood = (submissionData, monthOffset) =>
  API.post(`${API_ENDPOINTS.INSERT_FOOD}/${monthOffset}`, { submissionData });

export const allEmpInfo2 = () => API.get(API_ENDPOINTS.FETCH_ALL_USER_INFO);

export const FetchSingleUserInfo = (easyproId) =>
  API.get(`${API_ENDPOINTS.FETCH_SINGLE_USER_INFO}/${easyproId}`);

export const deleteUser2 = (id) =>
  API.delete(`${API_ENDPOINTS.DELETE_USER}/${id}`);

export const insertUser2 = (formData) =>
  API.post(API_ENDPOINTS.INSERT_USER, { formData });

export const userLogin2 = (id, password) =>
  API2.post(API_ENDPOINTS.USER_LOGIN, { id, password });

export const userRegistration2 = (id, password) =>
  API2.post(API_ENDPOINTS.USER_REGISTRATION, { id, password });

export const FetchSingleUserFood = (easyproId, monthOffset) =>
  API.get(`${API_ENDPOINTS.FETCH_SINGLE_USER_FOOD}/${monthOffset}`, {
    params: { easyproId },
  });

export const AllFoodHistory2 = (monthOffset) =>
  API.get(`${API_ENDPOINTS.FETCH_ALL_FOODS}/${monthOffset}`);

export const DeleteFoodItem = ({ easyproId, date, monthOffset }) =>
  API.patch(`${API_ENDPOINTS.DELETE_FOOD_ITEM}/${monthOffset}`, {
    easyproId,
    date,
  });

export const Update_Food_Item = ({ formData, monthOffset }) =>
  API.patch(`food-data/update-food-item/${monthOffset}`, { formData });

export const MONTHLY_SUMMARY = ({ branch, monthOffset }) =>
  API.get(`${API_ENDPOINTS.GET_FOOD_DATA}/${monthOffset}`, {
    params: { branch },
  });

export const DailyData = ({ branch, monthOffset }) =>
  API.get(`${API_ENDPOINTS.DAILY_FOOD_COUNTS}/${monthOffset}`, {
    params: { branch },
  });

export const FetchSingleUserInfoByAdmin = ({ id, monthOffset }) =>
  API.get(`${API_ENDPOINTS.FETCH_SINGLE_USER_INFO_BY_ADMIN}/${monthOffset}`, {
    params: { id },
  });

export const CheckedInData = ({ currentDate, monthOffset }) =>
  API.get(`${API_ENDPOINTS.CHECKED_IN_DATA}/${monthOffset}`, {
    params: { currentDate },
  });

export const DepartmentWiseData = (branch, monthOffset) =>
  API.get(`${API_ENDPOINTS.DEPARTMENT_WISE_DATA}/${monthOffset}`, {
    params: { branch },
  });

export const VerifyToken = () => API.get(API_ENDPOINTS.VERIFY_TOKEN);

export const PermissionData = (monthOffset) =>
  API.get(`${API_ENDPOINTS.PERMISSION_DATA}/${monthOffset}`);

export const updatePermissions = (_id, allow, monthOffset) =>
  API.patch(`${API_ENDPOINTS.UPDATE_PERMISSIONS}/${monthOffset}`, {
    _id,
    allow,
  });

export const updateUser = (formData) =>
  API.patch(API_ENDPOINTS.UPDATE_USER, { formData });

export const generate_food = ({ monthOffset }) => {
  return API.post(`${API_ENDPOINTS.GENERATE_FOOD}/${monthOffset}`);
};

//

export const checkbox_update_by_admin = ({ _id, monthOffset }) =>
  API.patch(`food-data/update-checkbox/${monthOffset}`, {
    _id,
  });
