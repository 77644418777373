import Kokoro from "../assets/kokoro.jpg";
import Tatsue from "../assets/tatsue.jpg";
import Sukoyaka from "../assets/sukoyaka.jpg";
import Nadesiko from "../assets/nadesiko.jpg";
import Shimane_Regular from "../assets/Shimane_western.png";
import Shimane_Western from "../assets/Shimane_regular.png";
import Tokoname from "../assets/Tokoname.jpg";
import Minoya from "../assets/minoya.png";
import Shinkawa_Tanaka from "../assets/shinkawa_tanaka.png";

export const Food_Data = [
  {
    id: 1,
    name: "たつえ",
    description:
      "ごはん普通盛り 冷凍食品の使用をしていない東海市のお弁当屋さん",
    image: Tatsue,
  },
  {
    id: 2,
    name: "たつえ大盛",
    description:
      "ごはん大盛り 冷凍食品の使用をしていない東海市のお弁当屋さん  ",
    image: Tatsue,
  },
  {
    id: 3,
    name: "たつえおかず",
    description: "おかずのみ 冷凍食品の使用をしていない東海市のお弁当屋さん",
    image: Tatsue,
  },
  {
    id: 4,
    name: "なでしこ",
    description:
      "カロリーが気になる女性に！量も少なめのお弁当です。ライスは詰めてあります",
    image: Nadesiko,
  },
  {
    id: 5,
    name: "こころ",
    description: "ごはん普通盛り ボリューム満点！おなかも満足なお弁当です",
    image: Kokoro,
  },
  {
    id: 6,
    name: "こころ大盛",
    description: "ごはん大盛り ボリューム満点！おなかも満足なお弁当です",
    image: Kokoro,
  },
  {
    id: 7,
    name: "こころおかず",
    description: "おかずのみ ボリューム満点！おなかも満足なお弁当です",
    image: Kokoro,
  },
  {
    id: 8,
    name: "健康弁当",
    description: "ライス230g メイン、副菜、漬物などバランスに優れたお弁当",
    image: Shimane_Regular,
  },
  {
    id: 9,
    name: "健康おかず",
    description: "ライスなし メイン、副菜、漬物などバランスに優れたお弁当",
    image: Shimane_Regular,
  },
  {
    id: 10,
    name: "洋風ランチ",
    description: "ライス230g 洋風なランチタイムをご希望の方に!",
    image: Shimane_Regular,
  },
  {
    id: 11,
    name: "洋風おかず",
    description: "ライスなし 洋風なランチタイムをご希望の方に!",
    image: Shimane_Western,
  },
  {
    id: 12,
    name: "常滑上",
    description: "ごはん普通盛り ボリューム満点！一番人気！！",
    image: Tokoname,
  },
  {
    id: 13,
    name: "常滑上大盛",
    description: "ごはん大盛り ボリューム満点！一番人気！！",
    image: Tokoname,
  },
  {
    id: 14,
    name: "常滑上おかず",
    description: "おかずのみ ボリューム満点！一番人気",
    image: Tokoname,
  },
  {
    id: 15,
    name: "すこやか",
    description: "ごはん普通盛り ヘルシーなランチ",
    image: Sukoyaka,
  },
  {
    id: 16,
    name: "すこやか大盛",
    description: "ごはん大盛り ヘルシーなランチ",
    image: Sukoyaka,
  },
  {
    id: 17,
    name: "すこやかおかず",
    description: "おかずのみ ヘルシーなランチ",
    image: Sukoyaka,
  },
  {
    id: 18,
    name: "ミノヤ",
    description: "おかずのみ ヘルシーなランチ",
    image: Minoya,
  },
  {
    id: 19,
    name: "田中新川",
    description: "おかずのみ ヘルシーなランチ",
    image: Shinkawa_Tanaka,
  },
];
