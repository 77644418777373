import { useState, useEffect } from "react";

// Define the screen size where you want to consider the device as mobile
const mobileScreenSize = 1024;

const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    isMobile: false,
  });

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      setWindowSize({
        width: width,
        isMobile: width <= mobileScreenSize,
      });
    };

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount and unmount

  return { windowSize };
};

export default useWindowSize;
