import { configureStore } from "@reduxjs/toolkit";

import translatorReducer from "./TranslatorReducer/translatorSlice";
import authReducer from "./auth";
import crudReducer from "./crud";

export const store = configureStore({
  reducer: {
    translator: translatorReducer,
    auth: authReducer,
    crud: crudReducer,
  },
});
