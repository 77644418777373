import React from "react";
import { useNavigate } from "react-router-dom";

const Modal = ({ showModal, setShowModal, message, toNavigate = false }) => {
  const navigate = useNavigate();
  // Function to handle modal close
  const closeModal = () => {
    setShowModal(false);
    if (toNavigate === true) {
      navigate("/");
    }
  };

  // If showModal is false, return null (modal is hidden)
  if (!showModal) return null;

  return (
    <div
      id="popup-modal"
      tabIndex="-1"
      className="fixed inset-0 z-50 flex items-center justify-center overflow-y-auto overflow-x-hidden"
    >
      <div className="fixed inset-0 bg-black opacity-50"></div>
      <div className="relative p-4 w-full max-w-md">
        <div className="relative bg-white rounded-lg shadow-lg dark:bg-gray-700">
          <div className="p-4 md:p-5 text-center">
            <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
              {message}
            </h3>
            <button
              type="button"
              className="text-white bg-green-400 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center me-2"
              onClick={closeModal}
            >
              Ok
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
