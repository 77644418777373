import React, { useEffect, useState, useRef } from "react";
import { tableStyle } from "../../tailwindClasses/Styles";
import { useSelector, useDispatch } from "react-redux";
import { delete_food_item, fetch_all_foods } from "../../actions/crud";
import LoadingPage from "../../components/LoadingPage";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import UpdateFoodModal from "../../components/UpdateFoodModal";

//* Months Addition
import { getMonthOffset } from "../../utils/monthOffsetUtils";
import MonthSelection from "../../components/MonthSelection";

import BranchSelection from "../../components/BranchSelection";

const FoodHistory = () => {
  const [currentBranch, setCurrentBranch] = useState("全て");
  const [showDeleteEmoji, setShowDeleteEmoji] = useState(false); // State to toggle visibility of the delete emoji
  const [showUpdateEmoji, setShowUpdateEmoji] = useState(false);
  const [showModal, setShowModal] = useState(false); // State to control modal visibility

  const [foodDetails, setFoodDetails] = useState({
    easyproId: 0,
    name: "",
    userName: "",
    date: "",
  });

  const tableRef = useRef(null);

  //* Months Addition
  const [monthValue, setMonthValue] = useState("this_month");
  const [monthOffset, setMonthOffset] = useState(getMonthOffset("this_month"));
  const months = ["previous_month", "this_month", "next_month"];

  const regex = /[^()]+(?=\()/;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetch_all_foods(monthOffset));
  }, [dispatch, monthOffset]);

  const handleCellClick = async (easyproId, date) => {
    // Handle cell click
    if (showDeleteEmoji) {
      try {
        dispatch(delete_food_item(easyproId, date));
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleUpdateClick = ({ easyproId, displayValue, userName, date }) => {
    setFoodDetails({
      easyproId: easyproId,
      name: displayValue,
      userName: userName,
      date: date,
    });
    setShowModal(true);
  };

  const fetchedData = useSelector((state) => state.crud.data.allFoods);

  if (!fetchedData) {
    return <LoadingPage />;
  }

  const allDates = fetchedData.flatMap(({ data }) =>
    data.map((item) => item.date)
  );
  const uniqueDates = allDates.filter(
    (date, index) => allDates.indexOf(date) === index
  );

  const branches = [
    "全て",
    "本社",
    "常滑",
    "知立",
    "島根",
    "名古屋",
    "東京",
    "博多",
  ];

  //* Months Addition
  const handleSelection = (e) => {
    const selectedMonth = e.target.value;
    setMonthValue(selectedMonth);
    setMonthOffset(getMonthOffset(selectedMonth));
  };

  return (
    <div className="w-screen h-screen bg-gradient-to-r from-blue-200 to-cyan-200">
      <div className="w-full h-1/5 flex  flex-row">
        <div className="w-1/6 h-full  flex">
          <div className="ml-6 my-auto">
            <BranchSelection
              currentBranch={currentBranch}
              setCurrentBranch={setCurrentBranch}
              branches={branches}
            />
          </div>
        </div>
        <div className="w-4/6 h-full "></div>
        <div className="w-1/6 h-full  flex flex-col">
          <div className="mx-auto my-auto bg-red w-auto h-auto flex flex-col space-y-4">
            <div className="flex flex-row space-x-4">
              <div className="mx-auto">
                <button
                  onClick={() => setShowDeleteEmoji(!showDeleteEmoji)}
                  className="bg-gradient-to-r from-cyan-400 to-blue-400 hover:bg-gradient-to-bl text-white font-bold py-2 px-4 rounded ml-auto"
                >
                  {showDeleteEmoji ? "戻る" : "削除"}
                </button>
              </div>
              <div className="mx-auto">
                <button
                  onClick={() => setShowUpdateEmoji(!showUpdateEmoji)}
                  className="bg-gradient-to-r from-cyan-400 to-blue-400 hover:bg-gradient-to-bl text-white font-bold py-2 px-4 rounded ml-auto"
                >
                  {showUpdateEmoji ? "戻る" : "更新"}
                </button>
              </div>
            </div>

            <div className="mx-auto">
              <MonthSelection
                months={months}
                onChange={handleSelection}
                monthValue={monthValue}
              />
            </div>
            <div className="bg-gradient-to-r from-cyan-400 to-blue-400 hover:bg-gradient-to-bl text-white font-bold py-2 px-4 rounded text-center">
              <ReactHTMLTableToExcel
                id="test-table-xls-button"
                table="table-to-xls"
                sheet="table-to-xls"
                filename={
                  monthOffset.substring(0, 6) + "-食の歴史-" + currentBranch
                }
                buttonText="エクスポート"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col h-screen mt-5">
        <div className="flex-grow overflow-auto">
          <div className="h-5/6 bg-red-200">
            <table className={tableStyle.table} id="table-to-xls">
              <thead className={tableStyle.thead} ref={tableRef}>
                <tr>
                  <th className={tableStyle.th}>EasyproId</th>
                  <th className={tableStyle.th}>氏名</th>
                  {uniqueDates.map((date) => (
                    <th key={date} className={tableStyle.th}>
                      {date.substring(5, 10)}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {fetchedData.map((eachData) => {
                  const { data, easyproId, userInfo } = eachData;

                  return (
                    <tr
                      key={easyproId}
                      className={tableStyle.special_tr}
                      tabIndex="0"
                    >
                      <td className="border px-2 py-4">{easyproId}</td>
                      <td className="border px-2 py-4">{userInfo?.name}</td>
                      {uniqueDates.map((date) => {
                        const foodForDate =
                          data.find((item) => item.date === date)?.name || "";
                        const displayValue =
                          currentBranch === "全て" ||
                          foodForDate.includes(currentBranch)
                            ? foodForDate
                            : "";

                        return (
                          <td key={date} className="border px-1 text-center">
                            {displayValue.match(regex)}{" "}
                            <div
                              className="cursor-pointer"
                              onClick={() => handleCellClick(easyproId, date)}
                            >
                              {foodForDate &&
                                displayValue &&
                                showDeleteEmoji &&
                                "❌"}
                            </div>
                            <div
                              className="cursor-grab"
                              onClick={() =>
                                handleUpdateClick({
                                  easyproId,
                                  displayValue,
                                  userName: userInfo.name,
                                  date,
                                })
                              }
                            >
                              {showUpdateEmoji && "🔄️"}
                            </div>
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className="h-1/6 bg-green-200"></div>
        </div>
      </div>
      <UpdateFoodModal
        showModal={showModal}
        data={foodDetails}
        setShowModal={setShowModal}
        message={"Update User Food"}
      />
    </div>
  );
};

export default FoodHistory;
