import React, { useState } from "react";
import {
  AppBar,
  Box,
  Button,
  Toolbar,
  Typography,
  Drawer,
  List,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import MenuIcon from "@mui/icons-material/Menu";
import { useNavigate } from "react-router-dom";

import LanguageSwitcher from "./LanguageSwitcher/LanguageSwitcher";

import { useLanguage } from "../hooks/useLanguage";
import * as actionType from "../constants/actionTypes";

const UserNavbar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const authData = useSelector((state) => state.auth.authData);

  let isLoggedIn = false;

  if (authData) {
    const { easyproId, role } = authData;
    isLoggedIn = easyproId && role === "employee";
  }

  const language = useLanguage();

  const handleLogout = () => {
    dispatch({ type: actionType.LOGOUT });
    navigate("/");
  };

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setOpen(open);
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const list = () => (
    <Box
      sx={{
        width: 250,
        paddingTop: "44px",
        height: "100%",
        backgroundColor: "aliceblue",
      }} // 64px is the typical height of a Material-UI AppBar
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        {isLoggedIn ? (
          <>
            <Button
              fullWidth
              variant="contained"
              component={RouterLink}
              to="/foodSelection"
              sx={{
                marginBottom: "10px",
                backgroundColor: "lightgrey",
                "&:hover": { backgroundColor: "green" },
                borderRadius: "16px",
              }}
            >
              {language.orders}
            </Button>

            <Button
              fullWidth
              variant="contained"
              component={RouterLink}
              to="/"
              onClick={handleLogout}
              sx={{
                marginBottom: "10px",
                backgroundColor: "lightgrey",
                "&:hover": { backgroundColor: "green" },
                borderRadius: "16px",
              }}
            >
              {language.logout}
            </Button>
          </>
        ) : (
          <>
            <Button
              fullWidth
              variant="contained"
              component={RouterLink}
              to="/register"
              sx={{
                marginBottom: "10px",
                backgroundColor: "lightgrey",
                "&:hover": { backgroundColor: "green" },
                borderRadius: "16px",
              }}
            >
              {language.register}
            </Button>
          </>
        )}
      </List>
    </Box>
  );

  return (
    <AppBar position="sticky" color="warning">
      <Toolbar>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          {isLoggedIn ? (
            <Button color="inherit" component={RouterLink} to="/profile">
              {language.homepage}
            </Button>
          ) : (
            <Button color="inherit" component={RouterLink} to="/">
              {language.login}
            </Button>
          )}
        </Typography>
        <LanguageSwitcher />
        {isMobile ? (
          <>
            <Button color="inherit" onClick={toggleDrawer(true)}>
              <MenuIcon />
            </Button>
            <Drawer anchor="right" open={open} onClose={toggleDrawer(false)}>
              {list()}
            </Drawer>
          </>
        ) : (
          <Box>
            {isLoggedIn ? (
              <>
                <Button
                  color="inherit"
                  sx={{ marginRight: 2 }}
                  component={RouterLink}
                  to="/foodSelection"
                >
                  {language.orders}
                </Button>

                <Button
                  color="inherit"
                  component={RouterLink}
                  to="/"
                  onClick={handleLogout}
                >
                  {language.logout}
                </Button>
              </>
            ) : (
              <>
                <Button color="inherit" component={RouterLink} to="/register">
                  {language.register}
                </Button>
              </>
            )}
          </Box>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default UserNavbar;
