export const special_button =
  "bg-gradient-to-r from-pink-500  to-yellow-500 text-white font-bold py-2 px-4 mt-5 rounded";

export const buttonStyle = {
  normal_button:
    "bg-slate-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded",
  special_button:
    "bg-gradient-to-r from-pink-500  to-yellow-500 text-white font-bold py-2 px-4 mt-2 rounded",
  normal_button_half: "text-white font-bold py-2 px-4 rounded",
};

export const normal_button =
  "bg-slate-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded";

export const table_tag = "min-w-full text-center  mt-10";

export const thead_tag =
  "bg-gray-200  font-bold text-xs border-y-1 border-gray-600";

export const th_tag =
  "px-2 py-2  text-gray-500 border border-gray-300 tracking-wider";

export const tbody_tag = "bg-white divide-y divide-x divide-gray-200";

export const tr_tag = "bg-white divide-y divide-gray-200";

export const td_tag =
  "px-2 py-2 whitespace-nowrap text-sm text-gray-500 border border-gray-300";

export const colors = {
  fc: "bg-lime-100",
  sc: "bg-sky-100",
  lc: "bg-stone-200",
};

export const tableStyle = {
  table: "shadow-lg bg-white border-collapse w-full relative",
  thead: "sticky top-0 border border-gray-300 bg-gray-200 h-20",
  th: "border border-gray-300 p-2",
  td: "text-center border border-gray-300 p-2",
  tr: "hover:bg-gray-100 focus:bg-gray-300",
  holiday_tr: "hover:bg-gray-300 bg-gray-200 border border-gray-900",
  special_tr: "hover:bg-gray-100 focus:bg-gray-300 text-xs",
};

export const Profile_Style_Laptop = {
  div1: "w-auto h-screen bg-gradient-to-r from-blue-200 to-cyan-200 flex overflow-x-auto",
  div2: "w-full h-4/5 flex flex-col",
  div3: "h-4/5 w-full  mt-20 flex flex-col",
  div4: "h-full w-full m-auto",
  div5: "flex items-center w-full h-1/6 ",
  div6: "w-1/5 mx-auto  border border-dotted border-slate-500 text-center",
  span1: "text-4xl font-extrabold dark:text-white",
  div7: "h-4/6 w-full  flex",
  div8: "w-full h-full  mx-auto flex justify-center",
  div9: "w-1/5 h-full  space-y-4  pr-3 text-right text-2xl bg-gradient-to-r from-neutral-300 to-stone-300 font-extrabold py-4",
  div10:
    "w-1/5 h-full  space-y-4  pl-3 text-left bg-gradient-to-r from-neutral-300 to-stone-300 text-2xl py-4",
  div11:
    "w-2/5 h-auto bg-gradient-to-r from-neutral-300 to-stone-300  flex flex-col mx-auto pb-4",
  div12:
    "border border-gray-300 mx-auto my-auto rounded-full text-gray-600 h-10 px-5 bg-white hover:border-gray-400 focus:outline-none appearance-none text-center",
  table1: "w-5/6 border mx-auto",
  tr: "text-center  border text-xl",
  td1: "text-right  font-serif  border border-slate-400 pr-4 w-1/2",
  td2: "text-left  font-sans border border-slate-400 pl-4 w-1/2",
};
export const Profile_Style_Mobile = {
  div1: "w-auto h-auto bg-gradient-to-r from-blue-200 to-cyan-200 flex overflow-x-auto",
  div2: "w-full h-4/5 flex flex-col",
  div3: "h-4/5 w-full bg-red-300 mt-20 flex flex-col",
  div4: "h-full w-full bg-gradient-to-r from-neutral-300 to-stone-300 m-auto",
  div5: "flex items-center w-full h-1/6 ",
  div6: "w-4/5 mx-auto border border-dotted border-slate-500 text-center mt-4",
  span1: "text-2xl font-extrabold dark:text-white",
  div7: "h-4/6 w-full mt-2",
  div8: "w-full h-full  mx-auto flex",
  div9: "w-1/2 h-full  space-y-4  pr-3 text-right text-xl font-extrabold",
  div10: "w-1/2 h-full  space-y-4  pl-3 text-left text-xl",
  div11: "w-full h-1/6  flex flex-col mt-6 mb-4",
  div12:
    "border border-gray-300 mx-auto my-auto rounded-full text-gray-600 h-10 px-5 bg-white hover:border-gray-400 focus:outline-none appearance-none text-center",
  table1: "w-5/6 border mx-auto",
  tr: "text-center  border text-xl",
  td1: "text-right  font-serif  border border-slate-400 pr-4 w-1/2",
  td2: "text-left  font-sans border border-slate-400 pl-4 w-1/2",
};

export const FoodSelection_Laptop = {
  div1: "w-auto h-screen bg-gradient-to-r from-emerald-400 to-cyan-400 flex flex flex-col",
  div2: "w-full  flex  h-1/5",
  special_div: "ml-0 pt-20",
  middlediv: "h-full pr-4 flex flex-col space-y-2 text-xl pt-16",
  div3: "text-cyan-950 pt-20 text-3xl mx-auto font-extrabold dark:text-white tracking-wider underline underline-offset-8",
  div4: "h-3/5 w-full  flex flex-col overflow-auto mx-2 mt-6",
  div5: "h-1/2 w-full   mt-8 space-x-8 flex flex-row overflow-auto",
  div6: "w-full h-full bg-gradient-to-r from-neutral-100 to-stone-200  flex flex-col hover:bg-gray-400 space-y-7",
  div6Disabled: "w-full h-full bg-stone-300  flex flex-col  space-y-7",
  div7: " w-full  text-center underline underline-offset-2 mt-3",
  div8: " text-normal mx-8",
  select1:
    "border border-gray-300 mx-auto  my-auto rounded-full text-gray-600 h-10 px-3 bg-white hover:border-gray-400 focus:outline-none appearance-none text-center",
  select2:
    "border border-gray-300 mx-auto my-auto rounded-full text-gray-600 h-8 px-2 bg-white hover:border-gray-400 focus:outline-none appearance-none text-center",
  div9: "bg-blue-300  flex mx-auto",
  div10: "w-full  mt-3 text-center text-xl",
  button1:
    "py-2.5 px-5 me-2 mb-2 mt-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-full border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700",

  divMiddle: " w-full  mx-auto flex",
  yellow_text: "text-yellow-200 pt-4 text-2xl",
  link_text: "my-2 mx-auto text-2xl underline underline-offset-4 text-gray-800",
};
export const FoodSelection_Mobile = {
  div1: "w-screen min-h-full bg-gradient-to-r from-blue-200 to-cyan-200 flex overflow-y-auto flex flex-col",
  div2: "mx-auto w-full flex flex-row",
  special_div: "ml-0 pt-20",
  middlediv: "h-full pr-2 flex flex-col space-y-1 pt-16",
  div3: "text-cyan-950 pt-20 text-xl font-extrabold dark:text-white tracking-wider underline underline-offset-8 mx-auto",
  div4: "h-2/5 w-full  mt-20 flex flex-col",
  div5: "h-5/6 mt-1 space-y-3",
  div6: "w-full h-20 bg-gradient-to-r from-neutral-100 to-stone-200 space-x-4  flex justify-center hover:bg-gray-300 ",
  div6Disabled: "w-full h-20 bg-stone-300 space-x-4  flex justify-center ",
  div7: "my-auto w-2/6  text-center underline underline-offset-2",
  div8: "my-auto mx-auto flex w-2/6 text-normal",
  select1:
    "border border-gray-300 mx-auto  my-auto rounded-full text-gray-600 h-8 px-3 bg-white hover:border-gray-400 focus:outline-none appearance-none text-center",
  select2:
    "border border-gray-300 mx-auto my-auto rounded-full text-gray-600 h-6 px-2 bg-white hover:border-gray-400 focus:outline-none appearance-none text-center",
  div9: " my-auto mx-auto w-1/6  flex",
  div10: "w-full  mt-3 text-center text-xl",
  divMiddle: "my-auto w-1/6 flex",
  yellow_text: "text-yellow-100 pt-4 text-base",
  link_text:
    "my-2 mx-auto text-normal underline underline-offset-4 text-gray-800",

  button1:
    "py-2.5 px-5 me-2 mb-2 mt-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-full border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700",
};

export const loginPage_Laptop = {
  div1: "w-screen h-screen bg-gradient-to-r from-blue-200 to-cyan-200  flex",
  div2: "w-full h-full  mx-auto my-auto flex",
  section1: " dark:bg-gray-900 w-full my-auto h-4/5",
  div3: "flex w-1/3 h-4/5 mx-auto bg-slate-100  flex-col items-center justify-center lg:py-0",
  div4: "flex my-auto items-center  text-2xl font-semibold text-gray-900 dark:text-white ",
  img1: "mx-auto w-48",
  div5: "w-full h-4/5 mb-4  rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700",
  div6: "p-6 space-y-4 md:space-y-6 sm:p-8",
  h1: "text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white",
  label: "block mb-2 text-sm font-medium text-gray-900 dark:text-white",
  input:
    "bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500",
  form: {
    div1: "w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700",
    div2: "p-6 space-y-4 md:space-y-6 sm:p-8",
  },
  button:
    "w-full text-blue bg-blue-300 hover:bg-blue-500 font-medium rounded-lg text-sm px-5 py-2.5 text-center",
  p: "text-sm font-light text-gray-500 dark:text-gray-400",
  last_div:
    "font-medium text-primary-600 underline underline-offset-4 hover:underline dark:text-primary-500 cursor-pointer",
};
export const loginPage_Mobile = {
  div1: "w-screen h-screen bg-gradient-to-r from-blue-200 to-cyan-200 flex",
  div2: "w-11/12 h-2/3  mx-auto my-auto flex",
  section1: " dark:bg-gray-900 w-full my-auto",
  div3: "flex bg-slate-100 flex-col items-center justify-center px-6 py-4 lg:py-0 mx-auto",
  div4: "flex items-center mb-6 text-2xl font-semibold text-gray-900",
  img1: "mx-auto w-48",
  div5: "w-full  rounded-lg  md:mt-0 sm:max-w-md xl:p-0  ",
  div6: "p-6 space-y-4 md:space-y-6 sm:p-8",
  h1: "text-xl font-bold leading-tight tracking-tight text-slate-600 md:text-2xl ",
  label: "block mb-2 text-sm font-medium text-slate-900 ",
  input:
    "bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5  ",
  form: {
    div1: "w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700",
    div2: "p-6 space-y-4 md:space-y-6 sm:p-8",
  },
  button:
    "w-full text-blue bg-blue-300 hover:bg-blue-500 font-medium rounded-lg text-sm px-5 py-2.5 text-center",
  p: "text-sm font-light text-slate-900 ",
  last_div: "font-medium text-primary-600 underline dark:text-primary-500",
};

export const ImageModalStyleLaptop = {
  div1: "absolute w-auto h-auto inset-0 flex ",
  div2: "relative bg-black w-1/2 h-4/6 mx-auto my-auto border border-gray-900 border-2 z-10",
  button1: "absolute top-2 right-2 px-2 bg-gray-900 text-white rounded",
  img1: "w-full h-full object-cover",
};

export const ImageModalStyleMobile = {
  div1: "absolute w-auto h-auto inset-0 flex",
  div2: "relative bg-black w-5/6 h-1/2 mx-auto my-auto border border-gray-900 border-2 z-10",
  button1: "absolute top-2 right-2 px-2 bg-gray-900 text-white rounded",
  img1: "w-full h-full object-fill",
};
