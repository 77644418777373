import React from "react";
import MyImage from "../assets/Gradient_builder_2.jpeg";
import { Food_Data } from "../data/FoodData";

const FoodInformation = () => {
  return (
    <div
      className="flex flex-wrap justify-around gap-6 bg-gray-200 p-20 min-h-screen bg-no-repeat bg-cover"
      style={{ backgroundImage: `url(${MyImage})` }}
    >
      {Food_Data.map((detail) => {
        const { id, name, description, image } = detail;
        return (
          <div
            key={id}
            className="w-full max-w-xs bg-slate-50 mb-2 mt-6 mx-2 flex-grow transition-transform duration-300 hover:scale-105 hover:shadow-lg shadow-md sm:flex-basis-1/2 md:flex-basis-1/3 lg:flex-basis-1/4"
          >
            <div className="cursor-pointer">
              <img className="w-full h-64 bg-contain" src={image} alt="bento" />
              <div className="p-4">
                <h5 className="text-lg font-bold mb-2">{name}</h5>
                <p className="text-sm text-gray-600 overflow-hidden h-10">
                  {description}
                </p>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default FoodInformation;
