// Function to calculate month offset based on selected month
export const getMonthOffset = (selectedMonth) => {
  const currentDate = new Date();
  let year = currentDate.getFullYear();
  let month = currentDate.getMonth();
  let day = currentDate.getDate();
  if (selectedMonth === "next_next_month") {
    month += 2;
    if (month === 12) {
      month = 1;
      year += 1;
    }
  } else if (selectedMonth === "next_month") {
    month += 1;
    if (month === 12) {
      month = 0;
      year += 1;
    }
  } else if (selectedMonth === "previous_month") {
    month -= 1;
    if (month === -1) {
      month = 11;
      year -= 1;
    }
  } else if (selectedMonth === "next_next_next_month") {
    month += 3;
    if (month === 12) {
      month = 2;
      year += 1;
    }
  }
  return `${year}-${month + 1}-${day}`;
};
