import React, { useEffect, useState } from "react";
// import { dateData } from "./FoodHistoryData";
import { tableStyle } from "../../tailwindClasses/Styles";
import { useSelector, useDispatch } from "react-redux";
import { checkIn_data, update_checkbox_by_admin } from "../../actions/crud";
import LoadingPage from "../../components/LoadingPage";
import CalendarComponent from "../../components/CalendarComponent";

function CheckedInStatus() {
  const branches = ["常滑", "本社", "知立", "島根", "名古屋", "東京", "博多"];
  const floors = [1, 2];
  const [branch, setBranch] = useState(branches[0]);
  const [floor, setFloor] = useState(1);

  const dispatch = useDispatch();
  const currentDate = useSelector((state) => state.crud.currentDate);

  useEffect(() => {
    dispatch(checkIn_data(currentDate));
  }, [currentDate, floor, dispatch]);

  const dateData = useSelector((state) => state.crud.data.checkInData);

  if (!dateData) {
    return <LoadingPage />;
  }

  const filteredData =
    branch === "常滑"
      ? dateData[branch].filter((item) => Number(item.floor) === Number(floor))
      : dateData[branch] || [];

  const handleBranchChange = (selectedBranch) => {
    setBranch(selectedBranch);
    setFloor(1); // Reset floor when switching branches
  };

  const handleFloorChange = (selectedFloor) => {
    setFloor(selectedFloor);
  };

  const handleCheckboxClick = ({ _id, date }) => {
    date = new Date(date).toISOString().split("T")[0];
    dispatch(update_checkbox_by_admin({ _id, date, branch }));
  };

  return (
    <div className="w-screen h-screen bg-gradient-to-r from-blue-200 to-cyan-200 flex flex-row overflow-y-auto">
      <div className="w-1/6 h-full  flex flex-row">
        <div className="w-1/2 mt-40  flex">
          <select
            className="border border-gray-300 mx-auto rounded-full text-gray-600 h-10 px-5 bg-white hover:border-gray-400 focus:outline-none appearance-none text-center"
            onChange={(event) => handleBranchChange(event.target.value)}
          >
            {branches.map((b) => (
              <option key={b} value={b} className="bg-gray-200">
                {b}
              </option>
            ))}
          </select>
        </div>
        <div className="w-1/2  my-4 mt-40 flex">
          {branch === "常滑" && (
            <select
              className="border border-gray-300 mx-auto rounded-full text-gray-600 h-10 px-5 bg-white hover:border-gray-400 focus:outline-none appearance-none text-center"
              onChange={(event) => handleFloorChange(event.target.value)}
            >
              {floors.map((f) => (
                <option
                  key={f}
                  value={f}
                  className={`bg-gray-200 ${
                    floor === f ? "text-white bg-red-500" : ""
                  }`}
                >
                  {f}階
                </option>
              ))}
            </select>
          )}
        </div>
      </div>
      <div className="w-4/6 h-full flex flex-col">
        <div className="h-1/6 w-full  flex">
          <div className="mx-auto my-auto text-3xl font-serif font-medium tracking-wider">
            日付 :: {currentDate}
          </div>
        </div>
        <div className="h-5/6 w-full">
          <div className="w-5/6 mx-auto">
            <table className={tableStyle.table}>
              <thead className={tableStyle.thead}>
                <tr>
                  <th className={tableStyle.th}>S. N.</th>
                  <th className={tableStyle.th}>EasyproId</th>
                  <th className={tableStyle.th}>氏名</th>
                  <th className={tableStyle.th}>食べ物</th>
                  {branch === "常滑" && <th className={tableStyle.th}>階</th>}
                  <th className={tableStyle.th}>チェックイン</th>
                </tr>
              </thead>
              <tbody>
                {filteredData.map((item, index) => (
                  <tr key={item._id} className={tableStyle.tr}>
                    <td className={tableStyle.td}>{index + 1}</td>
                    <td className={tableStyle.td}>{item.easyproId}</td>
                    <td className={tableStyle.td}>{item.userName}</td>
                    <td className={tableStyle.td}>{item.name}</td>
                    {branch === "常滑" && (
                      <td className={tableStyle.td}>{item.floor}</td>
                    )}
                    <td className={tableStyle.td}>
                      <input
                        type="checkbox"
                        checked={item.checkedIn}
                        className="accent-blue-800 w-4 h-4"
                        onChange={() =>
                          handleCheckboxClick({
                            _id: item._id,
                            date: item.date,
                          })
                        }
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="w-2/6 h-full flex">
        <div className="mt-40 mx-auto">
          <CalendarComponent />
        </div>
      </div>
    </div>
  );
}

export default CheckedInStatus;
