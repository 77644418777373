import React, { useEffect, useState } from "react";
import { useLanguage } from "../../hooks/useLanguage";
import {
  Profile_Style_Laptop,
  Profile_Style_Mobile,
} from "@tailwindClasses/Styles";
import { useSelector, useDispatch } from "react-redux";
import { fetch_single_user_info, fetch_single_user_foods } from "@actions/crud";

import { useNavigate } from "react-router-dom";

import { useTitle } from "../../hooks/useTitle";

import useWindowSize from "../../hooks/useWindowSize";
import { updateWorkLocation } from "@actions/auth";

// Function to calculate month offset based on selected month
const getMonthOffset = (selectedMonth) => {
  const currentDate = new Date();
  let year = currentDate.getFullYear();
  let month = currentDate.getMonth();
  let day = currentDate.getDate();
  if (selectedMonth === "previous_month") {
    month -= 1;
    if (month === -1) {
      month = 11;
      year -= 1;
    }
  } else if (selectedMonth === "next_month") {
    month += 1;
    if (month === 12) {
      month = 0;
      year += 1;
    }
  }
  return `${year}-${month + 1}-${day}`;
};

function Profile() {
  const userId = useSelector((state) => state.auth.authData.easyproId);
  const work_location = useSelector(
    (state) => state?.auth?.authData?.work_location
  );

  const [monthOffset, setMonthOffset] = useState(getMonthOffset("this_month"));

  const navigate = useNavigate();

  const { userInfo, userFood } = useSelector((state) => state.crud);

  const dispatch = useDispatch();

  const branches = ["常滑", "知立", "本社", "島根", "名古屋", "東京", "博多"];

  let Profile_Style = {};

  const handleLocationChange = (event) => {
    const newLocation = event.target.value;
    dispatch(updateWorkLocation(newLocation));
  };

  const language = useLanguage();
  useTitle(language.page_titles.profile_page);
  const easyproId = userId;
  const { windowSize } = useWindowSize();

  if (windowSize.isMobile) {
    Profile_Style = Profile_Style_Mobile;
  } else {
    Profile_Style = Profile_Style_Laptop;
  }
  const {
    div1,
    div2,
    div3,
    div4,
    div5,
    div6,
    div7,
    div8,
    div9,
    div10,
    div11,
    div12,
    span1,
    table1,
    tr,
    td1,
    td2,
  } = Profile_Style;

  useEffect(() => {
    const fetchData = () => {
      // Dispatch actions to fetch user info and foods
      dispatch(fetch_single_user_info(easyproId, language, navigate));
      dispatch(
        fetch_single_user_foods(easyproId, language, monthOffset, navigate)
      );
    };

    fetchData();
  }, [dispatch, easyproId, language, navigate, monthOffset]);

  return (
    <div className={div1}>
      <div className={div2}>
        <div className={div3}>
          <div className={div4}>
            <div className={div5}>
              <div className={div6}>
                <span className={span1}>{language.profile}</span>
              </div>
            </div>
            <div className={div7}>
              {userInfo && (
                <div className={div8}>
                  {/* {userInfo && <div className="w-1/2 h-full bg-red-300"></div>} */}

                  <div className={div9}>
                    <div>{language.employee_profile.easyproid} ::</div>
                    <div className="text-right">
                      {language.employee_profile.name} ::
                    </div>
                    <div className="text-right">
                      {language.employee_profile.employment_status} ::
                    </div>
                    <div className="text-right">
                      {language.employee_profile.group} ::
                    </div>
                    <div className="text-right">
                      {language.employee_profile.team} ::
                    </div>
                    <div className="text-right">
                      {language.employee_profile.work_location} ::
                    </div>
                  </div>
                  <div className={div10}>
                    <div> {userInfo.easyproId || "\u00A0"}</div>
                    <div> {userInfo.name || "\u00A0"}</div>
                    <div> {userInfo.employee_status || "\u00A0"}</div>
                    <div> {userInfo.company_group || "\u00A0"} </div>
                    <div> {userInfo.team || "\u00A0"} </div>
                    <div> {userInfo.work_location || "\u00A0"}</div>
                  </div>
                </div>
              )}
            </div>
            <div className={div11}>
              <div className="py-2 mx-auto font-bold tracking-widest text">
                {language.select_order_location}
              </div>
              <select
                value={work_location}
                onChange={handleLocationChange}
                className={div12}
              >
                {branches.map((branch) => (
                  <option
                    key={branch}
                    value={branch}
                    className="text-xl font-normal"
                  >
                    {branch}
                  </option>
                ))}
              </select>
              <div className="mx-auto py-4">
                <button
                  onClick={() => navigate("/foodselection")}
                  className="bg-gradient-to-r from-green-400 to-blue-400 hover:bg-gradient-to-bl text-white font-bold py-2 px-4 rounded-full"
                >
                  {language.next}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="h-1/5 min-w-full  mt-32">
          <div className="">
            {windowSize.isMobile ? (
              <div>
                {userFood &&
                  userFood.map((data) => (
                    <table className={table1} key={data.date}>
                      <tbody>
                        <tr className={tr}>
                          <td className={td1}>{data.date}</td>
                          <td className={td2}>{data.name}</td>
                        </tr>
                      </tbody>
                    </table>
                  ))}
              </div>
            ) : (
              <table>
                <thead>
                  <tr className="border">
                    {userFood &&
                      userFood.map((item) => (
                        <th key={item._id} className="border p-1 text-sm">
                          {item.date.substring(5)}
                        </th>
                      ))}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    {userFood &&
                      userFood.map((item) => (
                        <td key={item._id} className="border p-1 text-xs">
                          {item.name}
                        </td>
                      ))}
                  </tr>
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Profile;
