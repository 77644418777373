import React, { useState } from "react";

import { getMonthOffset } from "../../utils/monthOffsetUtils";
import { useDispatch } from "react-redux";
import { generate_food } from "../../actions/crud";
import MonthSelection from "../../components/MonthSelection";
import Modal from "../../components/Modal";
import { useLanguage } from "../../hooks/useLanguage";

function GenerateFood() {
  const [monthValue, setMonthValue] = useState("this_month");
  const [monthOffset, setMonthOffset] = useState(getMonthOffset("this_month"));
  const [showModal, setShowModal] = useState(false); // State for controlling the visibility of the modal

  const dispatch = useDispatch();
  const language = useLanguage();

  const months = [
    "previous_month",
    "this_month",
    "next_month",
    "next_next_month",
    "next_next_next_month",
  ];

  const handleSelection = (e) => {
    const selectedMonth = e.target.value;
    setMonthValue(selectedMonth);
    setMonthOffset(getMonthOffset(selectedMonth));
  };

  const handleSubmit = () => {
    const customMonthOffset = monthOffset
      .split("-")
      .slice(0, 2)
      .join("-")
      .replace(/-(\d)(?=\D|$)/g, "-0$1");
    dispatch(generate_food(monthOffset));

    // Show the modal after submission
    setShowModal(true);
  };

  return (
    <div className="w-full h-96 bg-gradient-to-r from-blue-200 to-cyan-200 flex flex-row">
      <div className="h-full w-1/6  flex">
        <div className="mx-auto mt-10">
          <MonthSelection
            months={months}
            onChange={handleSelection}
            monthValue={monthValue}
          />
        </div>
      </div>
      <div className="h-full w-4/6 ">
        <div className="w-full h-full  flex flex-col items-center">
          <div className="mt-10 text-3xl">
            {language.food_generation_page.selected_month} ::
            {"\u00A0".repeat(3)}
            {monthOffset
              .split("-")
              .slice(0, 2)
              .join("-")
              .replace(/-(\d)(?=\D|$)/g, "-0$1")}
          </div>

          <div className="mt-40 text-3xl">
            <button
              className="text-white bg-blue-500 hover:bg-green-600 focus:outline-none focus:ring-4 focus:ring-green-300 font-medium rounded-full text-2xl px-5 py-2.5 text-center me-2 mb-2 dark:focus:ring-yellow-900"
              onClick={handleSubmit}
            >
              {language.food_generation_page.generate_food}
            </button>
          </div>
        </div>
      </div>
      <div className="h-full w-1/6 "></div>
      <Modal
        showModal={showModal}
        setShowModal={setShowModal}
        message={language.food_generation_page.data_submission_thank} // Pass the message to the modal
      />
    </div>
  );
}

export default GenerateFood;
